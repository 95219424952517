import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route, Outlet } from 'react-router-dom';

import IndexRoute from './routes/IndexRoute';

export default function App() {
  return (
    <ChakraProvider>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<IndexRoute />} />
        </Route>
      </Routes>
    </ChakraProvider>
  );
}

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}