import { useEffect, useRef, useState } from "react";

import {
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";

import {
  BiBriefcaseAlt2,
  BiSolidBrush,
  BiSolidEraser,
  BiHighlight,
  BiSolidLayer,
  BiSolidLayerPlus,
  BiSolidTrash,
  BiSolidDuplicate,
} from "react-icons/bi";

import {
  Container,
  LayerSelectedActions,
  LayersContainer,
  LayersList,
  ToolboxContainer,
  ToolboxList,
} from "./PdfNotesEditor.styled";

import styles from "./PdfNotesEditor.module.css";

function PdfNotesEditor() {
  const containerRef = useRef();

  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const [showTools, setShowTools] = useState(false);
  const [showLayers, setShowLayers] = useState(false);

  const [layers, setLayers] = useState([]);
  const [selectedTool, setSelectedTool] = useState(undefined);

  const TOOLS = {
    BRUSH: 0,
    HIGHLIGHTER: 1,
    ERASER: 2,
  };

  useEffect(
    (_) => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.parentNode.offsetWidth);
        setContainerHeight(containerRef.current.parentNode.offsetHeight);
      }
    },
    [containerRef]
  );

  function onToolsToggle() {
    setShowTools(!showTools);
  }

  function onLayersToggle() {
    setShowLayers(!showLayers);
  }

  function addLayer() {
    if (layers.length >= 5) {
      return;
    }

    const newLayers = layers.map((l) => {
      l.selected = false;
      return l;
    });

    newLayers.push({
      n: newLayers.length + 1,
      label: `Layer ${newLayers.length + 1}`,
      selected: true,
    });

    newLayers.sort((a, b) => b.n - a.n);

    setLayers(newLayers);
  }

  function removeSelectedLayer() {
    const layer = layers.find((x) => x.selected);

    if (!layer) {
      return;
    }

    const newLayers = layers
      .filter((l) => l.n !== layer.n)
      .map((l) => {
        if (l.n > layer.n) {
          l.n--;
        }

        return l;
      });

    setLayers(newLayers);
  }

  function duplicateSelectedLayer() {
    const layer = layers.find((x) => x.selected);

    if (!layer) {
      return;
    }

    if (layers.length >= 5) {
      return;
    }

    const newLayers = layers.map((l) => {
      l.selected = false;
      return l;
    });
    newLayers.push({
      n: newLayers.length + 1,
      label: `Copy of layer ${layer.n}`,
      selected: true,
    });

    newLayers.sort((a, b) => b.n - a.n);

    setLayers(newLayers);
  }

  function selectLayer(n) {
    const newLayers = layers.map((l) => {
      l.selected = l.n === n;
      return l;
    });

    setLayers(newLayers);
  }

  function selectTool(tool) {
    setSelectedTool(tool);

    switch (tool) {
      case TOOLS.BRUSH:
        return selectBrush();
      case TOOLS.HIGHLIGHTER:
        return selectHighlighter();
      case TOOLS.ERASER:
        return selectEraser();
      default:
        return;
    }
  }

  function selectBrush() {}

  function selectHighlighter() {}

  function selectEraser() {}

  return (
    <Container
      ref={containerRef}
      width={containerWidth}
      height={containerHeight}
    >
      <ToolboxContainer>
        <Tooltip label={showTools ? "Close toolbox" : "Open toolbox"}>
          <IconButton
            onClick={onToolsToggle}
            icon={<Icon as={BiBriefcaseAlt2} boxSize={6} />}
            colorScheme="purple"
            size="lg"
          />
        </Tooltip>
        <ToolboxList visible={showTools ? "visible" : "hidden"}>
          <Tooltip label="Brush">
            <IconButton
              onClick={() => selectTool(TOOLS.BRUSH)}
              icon={<Icon as={BiSolidBrush} boxSize={6} />}
              isActive={selectedTool === TOOLS.BRUSH}
              colorScheme="pink"
              size="md"
            />
          </Tooltip>
          <Tooltip label="Highlighter">
            <IconButton
              onClick={() => selectTool(TOOLS.HIGHLIGHTER)}
              icon={<Icon as={BiHighlight} boxSize={6} />}
              isActive={selectedTool === TOOLS.HIGHLIGHTER}
              colorScheme="pink"
              size="md"
            />
          </Tooltip>
          <Tooltip label="Eraser">
            <IconButton
              onClick={() => selectTool(TOOLS.ERASER)}
              icon={<Icon as={BiSolidEraser} boxSize={6} />}
              isActive={selectedTool === TOOLS.ERASER}
              colorScheme="pink"
              size="md"
            />
          </Tooltip>
        </ToolboxList>
      </ToolboxContainer>
      <LayersContainer>
        <Tooltip label={showLayers ? "Hide layers" : "Show layers"}>
          <IconButton
            onClick={onLayersToggle}
            icon={<Icon as={BiSolidLayer} boxSize={6} />}
            colorScheme="purple"
            size="lg"
          />
        </Tooltip>
        <LayersList visible={showLayers ? "visible" : "hidden"}>
          <Tooltip label="Add layer">
            <IconButton
              onClick={addLayer}
              isDisabled={layers.length >= 5}
              className={styles.addLayerButton}
              icon={<Icon as={BiSolidLayerPlus} boxSize={6} />}
              colorScheme="pink"
              size="md"
            />
          </Tooltip>
          {layers.map((layer) => (
            <ButtonGroup
              isAttached
              key={layer.n}
              className={styles.layerButtonGroup}
            >
              <Button
                onClick={() => selectLayer(layer.n)}
                size="md"
                isActive={true}
                className={
                  layer.selected
                    ? styles.layerButtonSelectedSpan
                    : styles.layerButtonSpan
                }
              >
                {layer.n}
              </Button>
              <Button
                onClick={() => selectLayer(layer.n)}
                colorScheme={layer.selected ? "blue" : "gray"}
                className={styles.layerButton}
                size="md"
              >
                {layer.label}
              </Button>
            </ButtonGroup>
          ))}
        </LayersList>
        {layers.find((l) => l.selected) && (
          <LayerSelectedActions visible={showLayers ? "visible" : "hidden"}>
            <Tooltip label="Duplicate layer">
              <IconButton
                onClick={duplicateSelectedLayer}
                icon={<Icon as={BiSolidDuplicate} boxSize={5} />}
                colorScheme="pink"
                size="md"
              />
            </Tooltip>
            <Tooltip label="Delete layer">
              <IconButton
                onClick={removeSelectedLayer}
                icon={<Icon as={BiSolidTrash} boxSize={5} />}
                colorScheme="pink"
                size="md"
              />
            </Tooltip>
          </LayerSelectedActions>
        )}
      </LayersContainer>
    </Container>
  );
}

export default PdfNotesEditor;
