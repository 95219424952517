import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  background-color: white;
`;

export const ToolboxContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const ToolboxList = styled.div`
  --right-start: 48px;
  --right-end: 60px;
  --opacity-start: 0;
  --opacity-end: 1;

  position: absolute;
  top: 0;
  right: var(--right-end);
  display: flex;
  visibility: ${(p) => p.visible};

  @keyframes slideInToolBoxList {
    from {
      opacity: var(--opacity-start);
      right: var(--right-start);
    }
    to {
      opacity: var(--opacity-end);
      right: var(--right-end);
    }
  }

  @keyframes slideOutToolBoxList {
    from {
      visibility: visible;
      opacity: var(--opacity-end);
      right: var(--right-end);
    }
    to {
      visibility: hidden;
      opacity: var(--opacity-start);
      right: var(--right-start);
    }
  }

  ${(p) =>
    p.visible === "visible" &&
    css`
      animation: slideInToolBoxList 0.3s;
    `}

  ${(p) =>
    p.visible === "hidden" &&
    css`
      animation: slideOutToolBoxList 0.3s;
    `};

  & button:not(:last-child) {
    margin-right: 12px;
  }
`;

export const LayersContainer = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

export const LayersList = styled.div`
  --bottom-start: calc(100% - 12px);
  --bottom-end: 100%;
  --opacity-start: 0;
  --opacity-end: 1;

  position: absolute;
  bottom: var(--bottom-end);
  right: 0;
  display: flex;
  flex-direction: column;
  visibility: ${(p) => p.visible};

  @keyframes slideInLayersList {
    from {
      opacity: var(--opacity-start);
      bottom: var(--bottom-start);
    }
    to {
      opacity: var(--opacity-end);
      bottom: var(--bottom-end);
    }
  }

  @keyframes slideOutLayersList {
    from {
      visibility: visible;
      opacity: var(--opacity-end);
      bottom: var(--bottom-end);
    }
    to {
      visibility: hidden;
      opacity: var(--opacity-start);
      bottom: var(--bottom-start);
    }
  }

  ${(p) =>
    p.visible === "visible" &&
    css`
      animation: slideInLayersList 0.3s;
    `}

  ${(p) =>
    p.visible === "hidden" &&
    css`
      animation: slideOutLayersList 0.3s;
    `};

  & button {
    margin-bottom: 12px;
  }
`;

export const LayerSelectedActions = styled.div`
  --right-start: 48px;
  --right-end: 60px;
  --opacity-start: 0;
  --opacity-end: 1;

  position: absolute;
  bottom: 0;
  right: 60px;
  display: flex;
  visibility: ${(p) => p.visible};

  @keyframes slideInLayerSelectedActions {
    from {
      opacity: var(--opacity-start);
      right: var(--right-start);
    }
    to {
      opacity: var(--opacity-end);
      right: var(--right-end);
    }
  }

  @keyframes slideOutLayerSelectedActions {
    from {
      visibility: visible;
      opacity: var(--opacity-end);
      right: var(--right-end);
    }
    to {
      visibility: hidden;
      opacity: var(--opacity-start);
      right: var(--right-start);
    }
  }

  ${(p) =>
    p.visible === "visible" &&
    css`
      animation: slideInLayerSelectedActions 0.3s;
    `}

  ${(p) =>
    p.visible === "hidden" &&
    css`
      animation: slideOutLayerSelectedActions 0.3s;
    `};

  & button:not(:last-child) {
    margin-right: 12px;
  }
`;
