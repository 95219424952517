import PdfNotesEditor from '../components/PdfNotesEditor/PdfNotesEditor';

export default function IndexRoute() {
    return (
        <div style={{
            margin: '12px',
            width: '800px',
            height: '600px'
        }}>
            <PdfNotesEditor />
        </div>
    );
}